import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="logo">
          type-safe : Studio <br />
        </p>
      </header>
    </div>
  );
}

export default App;
